<template>
    <v-container class="white-bg">
        <v-row>
            <v-col cols="12" class="text-center">
                <h3 class="primary--text"><b>{{$t("YourPaymentTransactionStatus")}}</b></h3>
            </v-col>
        </v-row>
        <v-row class="pt-4 d-flex justify-space-between border-bottom">
            <v-col cols="6">
                {{$t("Status")}}
            </v-col>
            <v-col class="text-right" cols="6">
                {{$t(status_detail.status)}}
            </v-col>
        </v-row>
        <v-row class="d-flex justify-space-between border-bottom">
            <v-col cols="6">
                {{$t("Notes")}}
            </v-col>
            <v-col class="text-right" cols="6">
                {{$t(status_detail.notes)}}
            </v-col>
        </v-row>
        <v-row class="d-flex justify-space-between border-bottom">
            <v-col cols="6">
                {{$t("OrderID")}}
            </v-col>
            <v-col class="text-right" cols="6">
                {{status_detail.order_id}}
            </v-col>
        </v-row>
        <v-row class="d-flex justify-space-between border-bottom">
            <v-col cols="6">
                {{$t("TransactionAmount")}}
            </v-col>
            <v-col class="text-right" cols="6">
                {{Intl.NumberFormat().format(status_detail.payment_amount)}}
            </v-col>
        </v-row>
        <v-row class="d-flex justify-space-between border-bottom">
            <v-col cols="6">
                {{$t("PaymentChannelConfiguration")}}
            </v-col>
            <v-col class="text-right" cols="6">
                {{Intl.NumberFormat().format(status_detail.configuration_amount)}}
            </v-col>
        </v-row>
        <v-row class="d-flex justify-space-between border-bottom">
            <v-col cols="6">
                {{$t("DisburmentConfiguration")}}
            </v-col>
            <v-col class="text-right" cols="6">
                {{Intl.NumberFormat().format(status_detail.disburment_config)}}
            </v-col>
        </v-row>
        <v-row class="d-flex justify-space-between border-bottom">
            <v-col cols="6">
                {{$t("PaymentAmount")}}
            </v-col>
            <v-col class="text-right" cols="6">
                {{Intl.NumberFormat().format(status_detail.pay_amount)}}
            </v-col>
        </v-row>
        <!-- <v-row class="d-flex justify-space-between border-bottom">
            <v-col cols="auto">
                Total Amount
            </v-col>
            <v-col class="text-right" cols="auto">
                123000
            </v-col>
        </v-row> -->
        <v-row class="d-flex justify-space-between border-bottom">
            <v-col cols="auto">
                {{$t("PaymentChannel")}}
            </v-col>
            <v-col class="text-right" cols="auto">
                {{status_detail.payment_channel}}
            </v-col>
        </v-row>
        <v-footer fixed app width="auto" color="white" elevation="3">
            <v-container class="ma-0 pa-0">
                <v-row>
                    <v-col
                        class="ma-0 pa-0 pl-2 pr-2 pb-4"
                        lg="2"
                        md="3"
                        cols="12"
                        align-self="center"
                    >
                        <v-btn
                            rounded
                            block
                            class="text-capitalize background"
                            @click="close()"
                        >
                        {{ $t("Home") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            </v-footer>
    </v-container>
</template>
<script>
export default {
    data(){
        return {
            status_detail:{
                status:"",
                notes:"",
                order_id:"",
                transaction_amount:"",
                total_amount:"",
                payment_channel:""
            }
        }
    },
    methods:{
        close(){
            this.$router.push({ name: "Home", query:{ payment_token:this.$route.params.payment_token }});
        }
    },
    created(){
        this.$store.dispatch("payment/paymentDetail", this.$route.params.payment_token).then((response) => {
            if(response.status_code == 200){
                var paymentstatushistories = response.data.payment_info.payment_status_history
                var status = paymentstatushistories[paymentstatushistories.length - 1].payment_status
                this.status_detail.status = status

                // let paymentPageNotes = paymentstatushistories[paymentstatushistories.length - 1].notes
                let splitNotes = paymentstatushistories[paymentstatushistories.length - 1].notes.split(":")
                // if (splitNotes.length >= 2) {
                //     paymentPageNotes = splitNotes[1]
                // }
                // paymentPageNotes

                var codeNotes = splitNotes[0].replaceAll(' ', '')
                if(codeNotes == "4481"){
                    this.status_detail.notes = "EwalletPaymentFailed"
                } else if (codeNotes == "0000"){
                    this.status_detail.notes = "Paid"
                } else if (codeNotes == "4487"){
                    this.status_detail.notes = "Expired"
                } else if (codeNotes == "4416"){
                    this.status_detail.notes = "Canceled"
                }
                this.status_detail.order_id = response.data.payment_info.transaction_detail.order_id
                this.status_detail.payment_channel = response.data.payment_info.payment_detail.payment_channel.name
                this.status_detail.payment_amount = response.data.payment_info.payment_detail.amount
                this.status_detail.configuration_amount = Math.round(response.data.payment_info.detail_payment_amount.configuration)
                this.status_detail.disburment_config = Math.round(response.data.payment_info.detail_payment_amount.disburment_config)
                this.status_detail.pay_amount = Math.round(response.data.payment_info.detail_payment_amount.pay_amount);
                if (response.data.is_fee_included !== undefined) {
                    if (response.data.is_fee_included === false) {
                        this.status_detail.payment_amount += response.data.total_fee
                    }   
                }
                // let issuerServiceFee = 0
                if (status == "paid") {
                    this.status_detail.status = "Success";
                } else if (status == "submit") {
                    this.status_detail.status = "Waitingforpayment";
                } else if (status == "pending") {
                    this.status_detail.status = "Pending";
                } else if (status == "fail") {
                    this.status_detail.status = "Failed";
                } else if (status == "canceled") {
                    this.status_detail.status = "Canceled";
                } else if (status == "expired") {
                    this.status_detail.status = "Expired";
                }
                if (this.status === "paid") {
                    // const payload = paymentstatushistories[paymentstatushistories.length - 1].payload;
                    switch (response.data.payment_channel_code) {
                        case "alepay":
                            // issuerServiceFee = payload.transactionInfo.payerFee
                            this.showIssuerServiceFee = true
                            break
                        case "alepay_atm_card":
                            // issuerServiceFee = payload.transactionInfo.payerFee
                            this.showIssuerServiceFee = true
                            break
                        default:
                            // console.log("As Existing")
                    }
                }
            }
        })
    }
}
</script>
<style scoped>
.border-bottom{
    border-bottom: 1px solid #d3caca
}
.white-bg {
  background: white
}
.background{
    background-color: #2b9635!important;
    color: white;
}
</style>